import Loading from '@/components/ui/Loading'
import DisplayField from '@/components/ui/DisplayField'
import EmptyState from '@/components/ui/EmptyState'
import { mapGetters } from 'vuex'

export default {
  components: { Loading, DisplayField, EmptyState },
  methods: {
    _failureDataCase () {
      this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Caso não encontrado'})
      this.$router.push({ name: 'Cases' })
    }
  },
  computed: {
    ...mapGetters({
      caseRecord: 'cases/record',
      loading: 'cases/recordLoading'
    })
  }
}
