<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary" />
    {{ text }}
  </div>
</template>

<script>
  export default {
    props: {
      promise: Promise,
      promiseKey: String
    },
    data: () => ({
      loading: true,
      text: ''
    }),
    beforeMount () {
      this.promise && this.promise.then && this.promise.then(r => {
        this.loading = false
        const data = r.data()
        if (data) {
          const keys = this.promiseKey.split('.')
          this.text = keys.reduce((acc, key) => acc[key], data)
        } else {
          this.text = ''
        }
      })
    }
  }
</script>
