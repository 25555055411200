<template>
  <loading
    v-if="loading > 0"
    class="absolute-center" />
  <v-container
    v-else
    grid-list-lg
    class="pt-0 case-documents">
    <v-layout
      row
      wrap>
      <v-flex
        xs12
        sm12
        class="py-0">
        <div v-if="documents.length > 0">
          <vue-dropzone
            id="drop-documents"
            ref="dropDocuments"
            :options="dropzoneOptions"
            @vdropzone-sending="sendingFile"
            @vdropzone-success="uploadSuccess" />
          <v-data-table
            class="table-documents"
            :headers="headers"
            :items="documents"
            :server-items-length="-1"
            hide-default-footer>
            <template
              v-slot:item="props"
              class="transparent">
              <tr>
                <td class="px-2">
                  <a
                    :href="props.item.fileName"
                    target="_blank">{{ props.item.fileName }}</a>
                </td>
                <td class="px-2 text-center">
                  <promise-key
                    :promise="props.item.user"
                    promise-key="name" />
                </td>
                <td class="px-2 text-center">
                  {{ props.item.insertedAt }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
        <vue-dropzone
          v-else
          id="drop-empty"
          ref="dropDocuments"
          :options="dropzoneOptions"
          @vdropzone-sending="sendingFile"
          @vdropzone-success="uploadSuccess" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import casesMixin from '@/mixins/cases'
  import PromiseKey from '@/components/ui/PromiseKey'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import vueDropzone from 'vue2-dropzone'
  import { mapGetters } from 'vuex'

  export default {
    components: { vueDropzone, PromiseKey },
    mixins: [ casesMixin ],
    data () {
      return {
        headers: [
          { text: 'Nome', value: 'name', sortable: false },
          { text: 'Enviado', value: 'sendedBy', align: 'center', sortable: false },
          { text: 'Data', value: 'date', align: 'center', sortable: false }
        ],
        dropzoneOptions: {
          dictDefaultMessage: '<i aria-hidden="true" class="icon mediumGray--text material-icons" style="font-size: 50px;">file_upload</i><br>Arraste um arquivo ou  clique nessa área'
        }
      }
    },
    computed: {
      ...mapGetters({
        userId: 'user/id',
        documents: 'cases/documents',
        loading: 'cases/documentsLoading'
      }),
    },
    beforeMount () {
      this.dropzoneOptions.url = '/functions/caseUploadDocument'
      this.dropzoneOptions.headers = { 'Authorization': `Bearer ${this.$store.state.user.user.token}` }
      this.$store.dispatch('cases/getDocuments', this.$route.params.caseId)
    },
    methods: {
      sendingFile (file, xhr, formData) {
        formData.append('case', this.$route.params.caseId)
        formData.append('user', this.userId)
      },
      uploadSuccess (file) {
        this.$refs.dropDocuments.removeFile(file)
      }
    }
  }
</script>


<style lang="sass">
  #drop-empty
    height: calc(100vh - 100px)
    border: none
    position: relative

    .dz-message
      position: absolute
      top: calc(50% - 50px)
      left: 50%
      transform: translate(-50%, -50%)

  #drop-documents
    border: none
    min-height: 50px

    .dz-message
      margin: 0

  .table-documents
    .v-data-footer
      padding: 0 !important

    .v-data-footer__pagination
      margin-right: 22px

</style>
